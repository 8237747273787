* {
  margin: 0; 
  padding: 0;
}  

body {
	font: 12px Verdana, Arial, Helvetica, sans-serif;
	color: #000;
	background: #e8cd82 url(images/background.gif) repeat-x top left;
	margin:0px;
	padding:0px;
}

img { border:0; margin: 0; }

a { color: #582627; text-decoration: none; outline: none;}
a:hover { color: #e3cf5f; text-decoration: none; outline: none; }


/* Page */
#page { width: 1120px; margin: 0 auto; padding: 0; }

/* Header */
#header { width: 1120px; height: 170px; margin: 0; padding: 0; background: url(images/header.png) no-repeat center top; }

#sitename { 
  height: 60px; 
  font: 30px Verdana, Arial, Helvetica, sans-serif;
  color: #fff;
  line-height: 60px;
  margin: 0 50px 0 260px; 
  padding: 0;
  text-align: center; 
  background: url(images/sitename-bg.png) no-repeat center bottom; 
}
#sitetag { 
  height: 23px; 
  font: 16px Verdana, Arial, Helvetica, sans-serif;
  color: #fff;
  line-height: 23px;
  margin: 0; 
  padding: 0 50px 0 260px;
  text-align: center; 
}

/* Horizontal Main Menu */
#mainmenu1 { width: 890px; height: 65px; margin: 11px 0 0 0; padding: 0 40px 0 190px; }


/* Content og Page */
#pagecontent { overflow: hidden; width: 1120px; margin: 0; padding: 0; background: url(images/content-bg.png) repeat-y center top; }


/* Left Column */
#leftcol { float: left; width: 210px; margin: 0; padding: 0; }

/* Vertical Left Menu */
#mainmenu2-wrapper { margin: 0; padding: 15px 15px 20px 20px;  }
#mainmenu2-wrapper ul.navlist { margin: 0; padding: 15px 0 0 0; }
#mainmenu2-wrapper ul.navlist li {
  display: block;
  list-style-type: none;
  font: bold 12px Verdana, Arial, Helvetica, sans-serif;
	color: #582627;  
  text-transform:uppercase;
  line-height: 14px;
  padding: 5px 5px;
  background: url(images/leftmenu-bottombg.png) no-repeat left bottom; 
	text-decoration: none;
}
#mainmenu2-wrapper ul.navlist li a { color: #582627; text-decoration:none; padding: 5px 5px 5px 5px; }
#mainmenu2-wrapper ul.navlist li a:hover { color: #fff; text-decoration:none; } 


/* Left Menus */
.leftmenu-box { margin: 0; padding: 15px 15px 20px 20px; }
.leftmenu-box-title { margin: 0; padding: 5px 10px; background: #d0ab5b url(images/menutitle-bg.gif) repeat-x left top; }
.leftmenu-box-title h2 { 
  font: bold 12px Verdana, Arial, Helvetica, sans-serif;
	color: #FAEFB2;
  text-transform:uppercase; 
  margin: 0;
	padding: 0;
}
.leftmenu-box-body { 
  padding: 15px 5px 0 5px;
	font: 12px Verdana, Arial, Helvetica, sans-serif;
	color: #804001;
}


/* Center Column */
#centercol { 
  float: left; 
  width: 660px; 
  margin: 0; 
  padding: 20px; 
  background: #fdf2b5; 
  margin-bottom: -3000px; 
  padding-bottom: 3000px; 
}

/* Center Column for page.php?19 */
#centercol2 { 
  float: left; 
  width: 660px; 
  margin: 0; 
  padding: 20px; 
  background: #fdf2b5 url(images/centercol2-bg.gif) no-repeat center 200px; 
  margin-bottom: -3000px; 
  padding-bottom: 3000px; 
}

/* News Post */
.newspost { margin: 0; padding: 0 0 15px 0; }
.newstitle { margin: 0; padding: 5px 10px; background: #d0ab5b url(images/menutitle-bg.gif) repeat-x left top; }
.newstitle h2 { 
  font: 20px Verdana, Arial, Helvetica, sans-serif;
	color: #FAEFB2;
  text-transform:uppercase; 
  margin: 0;
	padding: 0;
}
.newsbody { 
  padding: 20px 0 0 0;
	font: 14px Verdana, Arial, Helvetica, sans-serif;
	color: #800000;
}


/* Center Menus */
.centermenu-box { margin: 0; padding: 15px 0 20px 0; }
.centermenu-box-title { margin: 0; padding: 5px 10px; background: #d0ab5b url(images/menutitle-bg.gif) repeat-x left top; }
.centermenu-box-title h2 { 
  font: 20px Verdana, Arial, Helvetica, sans-serif;
	color: #FAEFB2;
  text-transform:uppercase; 
  margin: 0;
	padding: 0;
}
.centermenu-box-body { 
  padding: 20px 0 0 0;
	font: 14px Verdana, Arial, Helvetica, sans-serif;
	color: #800000;
}


/* Right Column */
#rightcol { float: left; width: 210px; margin: 0; padding: 0; }
/* Right Menus */
.rightmenu-box { margin: 0; padding: 15px 20px 20px 15px; }
.rightmenu-box-title { margin: 0; padding: 5px 10px; background: #d0ab5b url(images/menutitle-bg.gif) repeat-x left top; }
.rightmenu-box-title h2 { 
  font: bold 12px Verdana, Arial, Helvetica, sans-serif;
	color: #FAEFB2;
  text-transform:uppercase; 
  margin: 0;
	padding: 0;
}
.rightmenu-box-body { 
  padding: 15px 5px 0 5px;
	font: 12px Verdana, Arial, Helvetica, sans-serif;
	color: #804001;
}

/* tbox, chatbox, button for left and right side */
#leftcol .tbox, #rightcol .tbox{ 
  font: 12px Verdana, Arial, Helvetica, sans-serif; 
  color: #000; 
  background: #d7a273;  
  margin:  1px 0; 
  padding: 5px; 
  border: 1px solid #99673a;
}
#leftcol .tbox.chatbox, #rightcol .tbox.chatbox { 
  width: 86%; 
  font: 12px Verdana, Arial, Helvetica, sans-serif; 
  color: #000; 
  background: #d7a273;  
  margin: 1px 0; 
  padding: 5px; 
  border: 1px solid #99673a;
}

/* Footer */
#footer { 
  width: 1120px; 
  height: 50px; 
  font: 11px Verdana, Arial, Helvetica, sans-serif; 
  color: #ac7f56; 
  text-align: center;
  margin: 0; 
  padding: 15px 0; 
  background: url(images/footer.png) no-repeat center top; 
}

/* Hack for textarea overflow */
textarea.tbox { width: 95%; }


/* Other Menus */
.othermenu-box { 
  margin: 0; 
  padding: 0;  
  background: #d2bb6a; 
  border: 1px solid #32313a;
}
.othermenu-title { 
  margin: 0;
  padding: 15px;
}
.othermenu-title h2 { 
  font: 24px Verdana, Arial, Helvetica, sans-serif;
  color: #894f39;
  line-height: 28px;
  margin: 0;
	padding: 0;
}
.othermenu-body {  
  font: 12px Arial, Helvetica, sans-serif;
	color: #76662d;
  line-height: 14px;
  margin: 0;
  padding: 15px;
}

/********************** core style ********************************/
/* tbox, chatbox, button */
.tbox{ 
  font: 13px Verdana, Arial, Helvetica, sans-serif; 
  color: #000; 
  background: #fff;  
  margin:  1px 0; 
  padding: 5px; 
  border: 1px solid #e7e2c8;
}
.tbox.chatbox { 
  width: 98%; 
  font: 13px Verdana, Arial, Helvetica, sans-serif; 
  color: #000; 
  background: #fff;  
  margin: 1px 0; 
  padding: 5px; 
  border: 1px solid #e7e2c8;
}
.button, .button:focus { 
  font: 10px Verdana, Arial, Helvetica, sans-serif; 
  color: #e0cdbe; 
  margin: 1px 0;
  padding: 3px 5px; 
  text-align: center;   
  background: #956438; 
  border: 1px solid #865930;
  -moz-border-radius: 4px;
	-khtml-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;

  text-transform: uppercase;
  text-decoration: none;
  cursor:pointer;   
}
.button:hover {
	font: 10px Verdana, Arial, Helvetica, sans-serif; 
  color: #fff; 
  margin: 1px 0;
  padding: 3px 5px; 
  text-align: center;   
  background: #956438; 
  border: 1px solid #865930;
  -moz-border-radius: 4px;
	-khtml-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
  text-transform: uppercase;
  text-decoration: none;
  cursor:pointer;    
  outline: none;
}
.button:active {
	font: 10px Verdana, Arial, Helvetica, sans-serif; 
  color: #fff;  
  margin: 1px 0;
  padding: 3px 5px; 
  text-align: center;   
  background: #956438; 
  border: 1px solid #865930;
  -moz-border-radius: 4px;
	-khtml-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;   
  text-transform: uppercase;
  text-decoration: none;
  cursor:pointer; 
  outline: none;
}  

/* FORUMS */
.border { border: 2px dotted #1a1a1a; }
.fborder { border: 2px dotted #1a1a1a; border-collapse: collapse; background: transparent; border: none; }  
.forumheader{ background: #b07948; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #fff; padding: 8px 10px; }
.forumheader a { color: #2a190a; text-decoration: none; }
.forumheader a:hover{ color: #fff; text-decoration: none; }
.forumheader2{ background: #d3c784; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #80542d; padding: 8px 10px; } 
.forumheader2 a { color: #2a190a; text-decoration: none; }
.forumheader2 a:hover{ color: #fff; text-decoration: none; }
.forumheader3{ background: #d3c784; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #80542d; padding: 8px 10px; }
.forumheader3 a { color: #2a190a; text-decoration: none; }
.forumheader3 a:hover{ color: #fff; text-decoration: none; }
.forumborder{ border: 1px dotted #1a1a1a; }
a.forumlink{ text-decoration: none; color: #FAEFB2; }
a.forumlink:hover { text-decoration: underline;color: #fff; }
.nforumcaption2 { background: #c6aa63; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #800000; padding: 8px 10px; }
.caption { background: #c6aa63; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #800000; padding: 8px 10px; }   
.fcaption { background: #c6aa63; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #800000; padding: 8px 10px; }
.fcaption a { text-decoration: none; color: #fff; }
.fcaption a:hover { text-decoration: underline; color: #fff; }
.finfobar{ background: #d3c784; font: 12px Verdana, Arial, Helvetica, sans-serif; color: #80542d; padding: 8px 10px; } 
.nforumholder { }
.nforumcaption { }
.nforumthread { }
.nforumreplycaption { }
.nforumreply { }
.nforumdisclaimer { }
.nforumview1 { }
.nforumview2 { }
.nforumview3 { }
.nforumview4 { }


/* TEXTS */
.indent{ font: italic 12px Verdana, Arial, Helvetica, sans-serif; color: #81773e; background: #e6deaf; border: 1px solid #cac293; padding: 10px; margin: 5px; } 
.smalltext { font: 11px Verdana, Arial, Helvetica, sans-serif; } 
.smallblacktext { font: 11px Verdana, Arial, Helvetica, sans-serif; }  
.defaulttext { font: 14px Verdana, Arial, Helvetica, sans-serif; }
.mediumtext { font: 12px Verdana, Arial, Helvetica, sans-serif; }
blockquote { font: italic 12px Verdana, Arial, Helvetica, sans-serif; color: #81773e; background: #e6deaf; border: 1px solid #cac293; padding: 10px; margin: 5px; }
.nextprev{ 
  clear: both;
  font: 12px Verdana, Arial, Helvetica, sans-serif; 
  color: #800000;
  margin: 0 auto; 
  padding: 15px 30px 50px 30px; 
  text-align: right;  
  background: transparent;
  border: none;
  text-align: right;
}
.nextprev_link { margin: 0 1px; padding: 3px 5px; }
a.nextprev_link {
  font: 12px Verdana, Arial, Helvetica, sans-serif;
	color: #fff;
  line-height: 28px;
  margin: 0;
  padding: 5px;
  background: #daa473;
}
a.nextprev_link:hover { 
  font: 12px Verdana, Arial, Helvetica, sans-serif;
	color: #fff;
  line-height: 28px;
  margin: 0;
  padding: 5px;
  background: #c18c5d;
}
.nextprev_current { 
  font: 12px Verdana, Arial, Helvetica, sans-serif;
	color: #800000;
  line-height: 28px;
  margin: 0;
  padding: 5px;
  background: transparent;
} 

.searchhighlight { text-decoration: underline; color: #ff0000; font-weight: bold; }


/* MISC */
td { font-family: Verdana, Arial, Helvetica, sans-serif; padding: 0; text-align:left; }
form { margin: 2px 0px 0px 0px; }
p { margin: 0px; padding: 0 0 10px 0; font: 12px Verdana, Arial, Helvetica, sans-serif; }
.spacer{ padding: 5px 0; }
hr { 
  width: 94% !important;
  border: none;
	clear: both;
	height: 1px;
	padding: 0;
	margin: 10px auto;
	background: #cd9664;
  border: none;
} 
.helpbox { width: 94%; background: transparent; font: 11px Arial, Helvetica, sans-serif; color:#8e6e4f; border: 0px; margin: 0; padding: 0; }
.bodytable { font-family: Verdana, Arial, Helvetica, sans-serif; padding: 8px; }
.code-box { font: 12px Verdana, Arial, Helvetica, sans-serif; color:#fff; padding: 8px; }
.code_highlight { 
  width: 600px; 
  margin: 5px auto;
  padding: 5px;
  text-align: left;
  display: block;
  overflow: auto;  
  font: 12px Arial, Helvetica, sans-serif;
  /* white-space: pre; */
  background: #e6deaf; 
  border: 1px solid #cac293;  
}
.code_highlight span { color: #000 !important; }
.b { border: none !important; }


/* Navigation ******************************************************************/
div.menuBar,
div.menuBar a.menuButton, div.menuButton, div.menuButton_over, 
div.menu,
div.menu a.menuItem {
  font: bold 12px Verdana, Arial, Helvetica, sans-serif;
	text-transform:uppercase;
  text-align: center; 
}

div.menuBar {
  list-style: none;
	margin: 0;
	padding: 0;
	height: 65px;
	line-height: 65px;
}

div.emenuBar {
  text-align: center; 
}

div.menuBar a.menuButton, div.menuButton, div.menuButton_over {
  height: 65px;
  line-height: 65px;
	margin: 0 5px;
	padding: 7px 8px 7px 6px;
	color: #804000;	
  cursor: pointer;
  text-align: center; 
  text-decoration: none;
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 100;   
  white-space: nowrap;
  background: transparent;
  border-right: 1px solid #b5844e;
}

div.menuBar a.menuButton:hover, div.menuButton:hover, div.menuButton_over {
	color: #fff;
	background: #b5844e;
}

div.menuBar a.menuButtonActive, div.menuButtonActive, div.menuButtonActive:hover, 
div.menuBar a.menuButtonActive:hover {
	color: #fff;
	background: #b5844e;
}

/* sub links container */
div.menu {
  position: absolute;
  left: -999em;
  top: 0px;
  left: 0px;
  margin-top: -1px;
  padding: 10px 4px 10px 4px;
  visibility: hidden;
  background: #b5844e;
  z-index: 101;      
}
/* sub links item */
div.menu a.menuItem {
  color: #fff;
  display: block;
	background: transparent;
	padding: 0 0 0 5px;
	text-decoration: none;
  white-space: nowrap;
  margin: 0 8px;
  text-align: left;
  font-variant: small-caps;  
  font: bold 10px/28px Verdana, Arial, Helvetica, sans-serif; 
  border-bottom: 1px dashed #9f7241;
}

div.menu a.menuItem:hover, div.menu a.menuItemHighlight {
	color: #fff;
	left: 0;
  top: 0;
	background: #9f7241;
}

div.menu a.menuItem span.menuItemBuffer {
  padding: 0px 2px 0px 2px;
  color: #fff;
}

div.menu a.menuItem:hover span.menuItemBuffer {
  padding: 0px 2px 0px 2px;
  color: #000;
}

div.menu a.menuItem span.menuItemText {
	padding: 5px 0 5px 20px;
	background: url(images/bullet.png) no-repeat left center;
}

div.menu a.menuItem span.menuItemText:hover {
	padding: 5px 0 5px 20px;
	background: url(images/bullet.png) no-repeat left center;
}

div.menu a.menuItem span.menuItemArrow {
  padding-right: 20px; 
}

div.menu div.menuItemSep {
  border-top: 0px solid #a5a5a5;
  border-bottom: 0px solid #fff;
  margin: 4px 2px;
}

a.menuItem.header {
  border-bottom: 0px solid #b3b3b3;
}

div.szechenyi {
    width: 332px;
    height: 233px;
    background: url(/e107_images/sz2020.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: fixed;
    z-index: 300000;
    bottom: 0;
    right: 0;
}

@media (max-width: 900px){
div.szechenyi {
    width: 50%;
    height: 20%;
    background-size: contain;
    background-position: right bottom;
}
}

div.akadalymentes {
    width: 50px;
    height: 50px;
    background: url(/e107_images/akadalymentes.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: fixed;
    z-index: 300000;
    top: 35px;

    right: 75px;
}
